import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CardActionArea} from "@mui/material";

const theme = createTheme();

export default function Gift() {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        const targetDate = new Date('2024-09-10T00:00:00'); // Date and time to compare

        const checkTime = () => {
            const currentDate = new Date();
            if (currentDate >= targetDate) {
                setShouldRender(true);
            } else {
                setShouldRender(false);
            }
        };

        checkTime(); // Initial check when component mounts

        const intervalId = setInterval(checkTime, 1000); // Optional: re-check every second

        return () => clearInterval(intervalId); // Cleanup on component unmount
    }, []);


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppBar position="relative">
                <Toolbar sx={{backgroundColor: '#346633'}}>

                </Toolbar>
            </AppBar>


            <main>
                {/* Hero unit */}
                <Box
                    sx={{
                        bgcolor: 'background.paper',
                        pt: 8,
                        pb: 6,
                        marginBottom: '0',
                        paddingBottom: '0'
                    }}
                >
                    <Container maxWidth="sm">
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Alles Gute zur Hochzeit liebe Melanie und Stefan!
                        </Typography>
                        {!shouldRender &&
                            <Typography variant="h5" align="center" color="text.secondary" paragraph>
                                Auch ich, deine persönliche Cloud, freue mich, dass ihr euch gefunden habt und wünsche
                                euch ein glückliches gemeinsames Leben!!!!
                                Ich habe ein ganz besonderes Geschenk für euch. Aber HALT! So schnell geht es nicht. Ihr
                                müsst ein wenig warten, bevor ich mich öffne, weil ich gerade so beschäftig bin. Am
                                besten ist, wenn ihr mich jeden Tag fragt, ob ich schon bereit bin.
                            </Typography>
                        }
                    </Container>
                </Box>

                {shouldRender &&
                    <Container sx={{py: 8, paddingTop: '0'}} maxWidth="md">
                        <Card>
                            <CardActionArea>
                                <CardContent>
                                    <Typography align="center" gutterBottom variant="h5" component="div">
                                        Dein Geschenk
                                    </Typography>
                                    <Typography variant="body1" color="text.secondary">
                                        Habt ihr gedacht, ich mache es euch so leicht? Sicher nicht!
                                        Natürlich respektiere ich euren Wunsch, jedoch müsst ihr euch das erst
                                        erarbeiten. Dafür müssen viele leichte bis schwierige Aufgaben lösen. Also, nun
                                        geht es endlich los:
                                        <p>
                                            Ich bin ein Kasten, voll mit Energie und Leistung und versorge viele Geräte.
                                            Bedenke, ich befinde mich aber nicht dort wo ihr glaubt. Du bist viele Jahre
                                            täglich an mir vorbeigegangen, ohne mich zu beachten.
                                            Hop hop, sonst werdet ihr nicht mehr fertig!
                                        </p>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Container>
                }
            </main>
        </ThemeProvider>
    );
}